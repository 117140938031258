import React from 'react';
import Page from '../components/Page';
import EmptyState from '../components/EmptyState';

const Feedback = () => {
  return (
    <Page
      title="Feedback"
      path="/feedback"
      description="Give feedback about readss!"
    >
      <EmptyState title="Feedback">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeNQXdbaMyrdI5FF2rkFjfr47tKqzN6vNREXDV15T0IZvte8w/viewform?embedded=true"
          width="100%"
          height="600px"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="Feedback"
        >
          Loading…
        </iframe>
      </EmptyState>
    </Page>
  );
};

export default Feedback;
